import gql from 'graphql-tag'

export const CreatePost = gql`
  mutation createPost($input: CreatePostInput!) {
    createPost(input: $input) {
      success
    }
  }
`

export const EditPost = gql`
  mutation editPost($input: EditPostInput!) {
    editPost(input: $input) {
      success
    }
  }
`

export const DeletePost = gql`
  mutation deletePost($input: DeletePostInput!) {
    deletePost(input: $input) {
      success
    }
  }
`

export const LikePost = gql`
  mutation likePost($input: LikePostInput!) {
    likePost(input: $input) {
      success
    }
  }
`

export const SharePost = gql`
  mutation sharePost($input: SharePostInput!) {
    sharePost(input: $input) {
      success
    }
  }
`

export const CreateComment = gql`
  mutation createComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      success
    }
  }
`

export const DeleteComment = gql`
  mutation deleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      success
    }
  }
`

export const LikeComment = gql`
  mutation likeComment($input: LikeCommentInput!) {
    likeComment(input: $input) {
      success
    }
  }
`
